import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";
import { i18n } from "../../config";
import CheckboxField from "../form-fields/CheckboxField";
import TextField from "../form-fields/TextField";
import Address from "../Address";

import { CircularProgress, Button, Box, Typography, Divider, Grid } from "@material-ui/core";
import { Check, AccountBalance, AccountCircle, WbIncandescent, Home, Whatshot, MoneyOff, PlaylistAddCheck } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    electricity: state.newElectricityContract,
    gas: state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const Confirm = (props) => {

  const handleSubmit = async (values) => {
    props.submitConfirmData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const theme = useTheme();
  const matchesBreakpointXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const lateralPageMargins = matchesBreakpointXsDown ? 0 : 50;

  return (
    <>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <Check fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_confirmation')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          promotionalCode: props.electricity.promotionalCode,
          acceptOVConditions: props.electricity.acceptOVConditions,
          acceptGdpr: props.electricity.acceptGdpr,
        }}
        validate={(values) => {
          const errors = {};

          if (!props.isAuthenticated) {
            if (!values.acceptOVConditions) {
              _.set(errors, "acceptOVConditions", i18n.t('common:text.required_field'));
            }
            if (!values.acceptGdpr) {
              _.set(errors, "acceptGdpr", i18n.t('common:text.required_field'));
            }
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}}>
                <Home fontSize="medium" color="secondary" style={{padding: 10}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {i18n.t('common:text.contractation_about_home')}
                </Typography>
              </Box>

              <Grid container style={{marginLeft: lateralPageMargins, marginRight: lateralPageMargins}}>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_supply_type_validation')}:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                    <Typography variant="body1">
                    {i18n.t(`common:text.contractation_${props.electricity.residenceType}_supply`)}
                  </Typography>
                </Grid>
                {!_.isEmpty(props.electricity.address) &&
                  <Grid container item xs={12}>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {i18n.t('common:text.contractation_address_validation')}
                      </Typography>
                    </Grid>
                      <Grid item xs={12} sm={9} md={10}>
                      <Address readonly value={props.electricity.address} />
                    </Grid>
                  </Grid>
                }
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_cnae_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    {_.get(props, "electricity.cnae.descripcio", "?")}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}}>
                <AccountCircle fontSize="medium" color="secondary" style={{padding: 10}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {i18n.t('common:text.contractation_about')}{" "}
                  {props.electricity.company
                    ? i18n.t('common:text.contractation_enterprise')
                    : i18n.t('common:text.contractation_you')}
                </Typography>
              </Box>

              <Grid container style={{marginLeft: lateralPageMargins}}>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {!props.electricity.company && i18n.t('common:text.contractation_name_validation')}
                    {props.electricity.company && i18n.t('common:text.contractation_company_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {!props.electricity.company && <>{props.electricity.surName1} {props.electricity.surName2}, {props.electricity.name}</>}
                    {props.electricity.company && props.electricity.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_dni_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {_.get(props, "electricity.vat", "").replace("ES", "")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_mobile_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {props.electricity.mobile}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_email_validation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10}>
                  <Typography variant="body1">
                    {props.electricity.email}
                  </Typography>
                </Grid>

                {!_.isEmpty(props.electricity.invoiceAddress) &&
                  <Address readonly value={props.electricity.invoiceAddress} />}

                {props.electricity.company &&
                  <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {i18n.t('common:text.contractation_legal_representative')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {props.electricity.surName1Representante}{" "}
                        {props.electricity.surName2Representante}, {props.electricity.nameRepresentante}{" "}
                        {props.electricity.vatRepresentante.replace("ES", "")}
                      </Typography>
                    </Grid>
                  </>
                }
              </Grid>
            </Box>


            {props.electricity.selectedProduct &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <WbIncandescent fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_about_contract')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_cups_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">
                      {props.electricity.cups}
                    </Typography>
                  </Grid>

                  <>
                    <Grid item xs={12} sm={3} md={2}>
                      <Typography variant="body1" style={{fontWeight: "bold"}}>
                        {i18n.t('common:text.contractation_tariff')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                      <Typography variant="body1">
                        {props.electricity.selectedProduct.name}
                      </Typography>
                    </Grid>
                  </>
                </Grid>
              </Box>
            }

            {props.gas.selectedProduct &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <Whatshot fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_about_gas_contract')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_cups_validation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">
                      {props.gas.cups}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} md={2}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_tariff')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={10}>
                    <Typography variant="body1">
                      {props.gas.selectedProduct.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            }

            <Box style={{ marginTop: 15 }}>
              <Box display={"flex"} style={{margin: 0}}>
                <AccountBalance fontSize="medium" color="secondary" style={{padding: 10}}/>
                <Typography variant="h6" style={{alignSelf: "center"}}>
                  {i18n.t('common:text.contractation_about_payment')}
                </Typography>
              </Box>

              <Grid container style={{marginLeft: lateralPageMargins}}>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1" style={{fontWeight: "bold"}}>
                    {i18n.t('common:text.contractation_iban_direct_debit')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="body1">
                    {props.electricity.iban}
                  </Typography>
                </Grid>

              </Grid>
            </Box>

            {_.get(Settings, "features.promotionalCode", false) &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <MoneyOff fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_promotional_code_title')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" style={{fontWeight: "bold"}}>
                      {i18n.t('common:text.contractation_promotional_code_title')}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Field
                      name="promotionalCode"
                      component={TextField}
                      label={i18n.t('common:text.contractation_promotional_code')}
                      style={{width: "80%", marginTop: matchesBreakpointXsDown ? '-10px' : '-20px'}}
                    />
                  </Grid>
                </Grid>
              </Box>
            }

            {!props.isAuthenticated &&
              <Box style={{ marginTop: 15 }}>
                <Box display={"flex"} style={{margin: 0}}>
                  <PlaylistAddCheck fontSize="medium" color="secondary" style={{padding: 10}}/>
                  <Typography variant="h6" style={{alignSelf: "center"}}>
                    {i18n.t('common:text.contractation_conditions')}
                  </Typography>
                </Box>

                <Grid container style={{marginLeft: lateralPageMargins}}>
                  <Grid item xs={12}>
                    <Field
                      name="acceptOVConditions"
                      label={i18n.t('common:text.contractation_ov_aceptation')}
                      component={CheckboxField}
                    />
                    <a
                      style={{ marginTop: 17 }}
                      target="_blank"
                      href={_.get(Settings, "links.conditions", "")}
                      rel="noreferrer"
                    >
                      {i18n.t('common:text.contractation_ov_conditions')}
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="acceptGdpr"
                      label={i18n.t('common:text.contractation_privacy_policy_aceptation')}
                      component={CheckboxField}
                    />
                    <a
                      style={{ marginTop: 17 }}
                      target="_blank"
                      href={_.get(Settings, "links.gdpr", "")}
                      rel="noreferrer"
                    >
                      {i18n.t('common:text.contractation_privacy_policy')}
                    </a>
                  </Grid>
                </Grid>
              </Box>
            }

            <Divider style={{marginTop: 20, marginBottom: 20}}/>
            <Button
              variant={'text'}
              onClick={handleGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {i18n.t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              variant={'contained'}
              color={'primary'}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.contractation_send')}
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
