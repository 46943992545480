import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import CheckboxField from "../form-fields/CheckboxField";
import IBANField, { IBANvalid } from "../form-fields/IBANField";
import { i18n } from "../../config";

import { CircularProgress, Button, Box, Typography, Grid } from "@material-ui/core";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Settings from "../../settings";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormPayment = (props) => {

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    props.submitPaymentData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <AccountBalanceIcon fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_about_payment')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          iban: props.iban,
          isOwner: props.isOwner,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.iban) {
            errors.iban = i18n.t('common:text.required_field');
          } else if (!IBANvalid(values.iban)) {
            errors.iban = i18n.t('common:text.iban_field_invalid');
          }

          if (!values.isOwner) {
            errors.isOwner = i18n.t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0} style={{marginBottom: 20}}>
              <Grid item xs={12} style={{marginBottom: 10}}>
                <Typography variant="h6" color="primary">
                  {i18n.t('common:text.contractation_about_payment')}
                </Typography>
                <Typography variant="body2">
                  {i18n.t('common:text.contractation_bank_details_info')}
                </Typography>
              </Grid>
              <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
                <Typography variant="h6">
                  {i18n.t('common:text.contractation_iban_full_name')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid container item xs={12} style={{paddingTop: 0}}>
                    <Grid item xs={6}>
                      <Field
                        name="iban"
                        component={IBANField}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{paddingTop: 0}}>
                    <Field
                      name="isOwner"
                      label={
                        <Typography variant="body1" style={{color: "black"}}>
                          {i18n.t('common:text.contractation_owner_confirmation')}
                        </Typography>
                      }
                      component={CheckboxField}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Button
              variant={'text'}
              onClick={handleGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {i18n.t('common:text.contractation_previous')}
            </Button>
            <Button
              type="submit"
              color={'primary'}
              variant={'contained'}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.contractation_next')}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPayment);
